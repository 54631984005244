import React from 'react'

import logo from '../assets/images/beleserv.png';

const Header = (props) => (
    <header id="header" className="alt">
        <span className="logo"><img src={logo} alt="" /></span>
        <h1>Building a better future with you</h1>
        {/*<p>Building a better future with you.</p>*/}
    </header>
);

export default Header
