import React from 'react'
import { Link } from 'gatsby'

const now = new Date;

const Footer = (props) => (
    <footer id="footer">
        <section>
            <h2>Your business deserves success</h2>
            <p>
              We would love to hear about your business obstacles and come up with a solution.
              We excel in using modern technologies to give you a competitive edge.
            </p>
            <ul className="actions">
                <li><Link to="/contact" className="button">Get in touch</Link></li>
            </ul>
        </section>
        <section>
            <h2>Beleserv</h2>
            <dl className="alt">
                <dt>Location</dt>
                <dd>Vienna &bull; Austria</dd>
                <dt>Email</dt>
                <dd><a href="#">info@beleserv.com</a></dd>
            </dl>
            <ul className="icons">
                {/*<li><a href="#" className="icon fa-twitter alt"><span className="label">Twitter</span></a></li>*/}
                {/*<li><a href="#" className="icon fa-facebook alt"><span className="label">Facebook</span></a></li>*/}
                {/*<li><a href="#" className="icon fa-instagram alt"><span className="label">Instagram</span></a></li>*/}
                <li><a href="https://github.com/beleserv" className="icon fa-github alt"><span className="label">GitHub</span></a></li>
                {/*<li><a href="#" className="icon fa-dribbble alt"><span className="label">Dribbble</span></a></li>*/}
            </ul>
        </section>
        <p className="copyright">&copy; Beleserv {now.getFullYear()}. Design: <a rel="nofollow" href="https://html5up.net">HTML5 UP</a>.</p>
    </footer>
);

export default Footer
