import React from 'react';
import {Link} from 'gatsby';
import Helmet from 'react-helmet';
import Waypoint from 'react-waypoint';

import Layout from '../components/layout';
import Header from '../components/Header';
import Nav from '../components/Nav';
import bojan from '../assets/images/bojan.jpg';
import polona from '../assets/images/polona.jpg';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stickyNav: false
    }
  }

  _handleWaypointEnter = () => {
    this.setState(() => ({stickyNav: false}));
  };

  _handleWaypointLeave = () => {
    this.setState(() => ({stickyNav: true}));
  };

  render() {

    return (
      <Layout>
        <Helmet title="Beleserv"/>

        <Header/>

        <Waypoint
          onEnter={this._handleWaypointEnter}
          onLeave={this._handleWaypointLeave}
        >
        </Waypoint>
        <Nav sticky={this.state.stickyNav}/>

        <div id="main">
          <section id="services" className="main special">
            <header className="major">
              <h2>Services</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Frontend development</h3>
                <p>Javascript, jQuery, ReactJS, Redux, HTML5, Bootstrap</p>
              </li>
              <li>
                <span className="icon major style3 fa-server"></span>
                <h3>Backend development</h3>
                <p>PHP, Laravel, Node.js, Elasticsearch, MySQL, SQLite</p>
              </li>
              <li>
                <span className="icon major style5 fa-language"></span>
                <h3>Translation</h3>
                <p>SDL Trados, German, Slovenian, Spanish</p>
              </li>
            </ul>
          </section>

          <section id="people" className="main special">
          <header className="major">
            <h2>People</h2>
          </header>
          <div className="spotlight">
            <div className="content" style={{margin: '0 20px'}}>
              <span className="image" style={{marginLeft: 0}}><img src={bojan} alt=""/></span>
              <header className="major">
                <h2>Bojan Bedrač</h2>
              </header>
              <p>
                Bojan is a passionate web developer living in Vienna with a special focus on quality, innovation and
                optimization.
                In his previous positions he developed many algorithms that directly saved time and money while giving
                the companies/organizations a competitive edge.
                He loves automation, process optimization and tackling difficult problems that others deem
                unsolvable.</p>
            </div>

            <div className="content" style={{margin: '0 20px'}}>
              <span className="image" style={{marginLeft: 0}}><img src={polona} alt=""/></span>
              <header className="major">
                <h2>Polona Bedrač</h2>
              </header>
              <p>
                Polona is a very focused and detail oriented translator and organizer.
                Her experience ranges from translating, managing translation projects, office organization and many
                more.
                She approaches every task with due diligence and delivers quick and efficient results.
                She loves identifying wasteful processes and comes up with incredible and simple improvements.
              </p>
            </div>
          </div>
        </section>

          <section id="products" className="main special">
            <header className="major">
              <h2>Products</h2>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-code"></span>
                <h3>Improve bad code</h3>
                <p>Blog about becoming a better developer.</p>
                <a href="https://www.improvebadcode.com" className="button special">Start reading</a>
              </li>
            </ul>
          </section>

          <section id="contact" className="main special">
            <header className="major">
              <h2>Talk to us</h2>
              <p>
                Let's start a conversation about your business obstacles.<br/>
                We love making an impact on small and medium businesses.
              </p>
            </header>
            <footer className="major">
              <ul className="actions">
                <li><Link to="/contact" className="button special">Drop us a line</Link></li>
              </ul>
            </footer>
          </section>

        </div>

      </Layout>
    )
  }
}

export default Index
