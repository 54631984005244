import React from 'react'
import Scrollspy from 'react-scrollspy'
import Scroll from './Scroll'

const Nav = (props) => (
    <nav id="nav" className={props.sticky ? 'alt' : ''}>
        <Scrollspy items={ ['services', 'people', 'contact'] } currentClassName="is-active" offset={-300}>
            <li>
                <Scroll type="id" element="services">
                    <a href="#">Services</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="people">
                    <a href="#">People</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="products">
                    <a href="#">Products</a>
                </Scroll>
            </li>
            <li>
                <Scroll type="id" element="contact">
                    <a href="#">Contact us</a>
                </Scroll>
            </li>
        </Scrollspy>
    </nav>
);

export default Nav
