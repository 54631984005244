import React from 'react'
import '../assets/scss/main.scss'
import CookieConsent from 'react-cookie-consent';

import Footer from './Footer'

class Template extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: 'is-loading'
    }
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
  }

  render() {
    const { children } = this.props

    return (
      <div className={`body ${this.state.loading}`}>
        <div id="wrapper">

          {children}
          <CookieConsent
            location="bottom"
            buttonText="Accept"
            declineButtonText="Decline"
            enableDeclineButton
            flipButtons={true}
            cookieName="gatsby-gdpr-google-analytics"
            cookieValue={true}
            declineCookieValue={false}
            style={{ backgroundColor: "#ffffff", color: '#000000', fontSize: '15px', fontFamily: '"Source Sans Pro", Helvetica, sans-serif', boxShadow: 'rgba(0, 0, 0, 0.5) 0px 9px 10px 5px', borderTop: '1px solid #cbd6e2' }}
            buttonStyle={{ color: "#ffffff", fontFamily: '"Source Sans Pro", Helvetica, sans-serif', padding: 0, backgroundColor: '#000000', borderRadius: '3px', fontSize: "15px" }}
            declineButtonStyle={{ color: "#000000", fontFamily: '"Source Sans Pro", Helvetica, sans-serif', padding: 0, backgroundColor: '#ffffff', borderRadius: '3px', border: '1px solid #000000', fontSize: "15px" }}
            expires={150}
          >
            This website stores cookies on your computer. These cookies are used to collect information about how you interact with this website and allow us to remember you.<br />We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors on this website.
            <br /><br />
            If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.
          </CookieConsent>
          <Footer />
        </div>
      </div>
    )
  }
}

export default Template
